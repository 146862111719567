import React from 'react';
import Layout from '@/core/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import { IntusLoader } from '@/shared/elements';
import { sec } from '@/middlewares/security';
import { useFetchUserQuery } from '@/store/apis/userApi';
import ResolutionError from '@/components/ResolutionError';
import { useMinimalScreenResolution } from '@/shared/hooks/useMinimalScreenResolution';

const App = () => {
  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const isUserFetching = useFetchUserQuery(undefined, {
    skip: !isAuthenticated,
  }).isLoading;

  const { showScreenResolutionError } = useMinimalScreenResolution();

  // required line to allow access to token within Http interceptor
  sec.setAccessTokenSilently(getAccessTokenSilently);

  const isAppLoading = isUserFetching || isLoading;

  if (showScreenResolutionError) {
    return <ResolutionError />;
  }

  return (
    <div className="App h-full">
      <div className="h-full flex flex-col">
        {isAppLoading ? (
          <IntusLoader embedded={false} loading={isAppLoading} />
        ) : (
          <Layout />
        )}
      </div>
    </div>
  );
};

export default App;
