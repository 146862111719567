import { FlatVector2, UnitSystemTypes } from '@/models';
import { WindowFrame } from '@/models/window-configurator.model';

export interface WindowCreatorFormData extends WindowFrame {
  units: UnitSystemTypes;
  glazing?: string;
  view: View;
}

export interface SavedWindow extends WindowFrame {
  guid: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  model: string;
}

export enum MeasurementElementType {
  Offset = 'offset',
  Frame = 'frame',
  Mullion = 'mullion',
  MullionPlacement = 'mullion_placement',
  Contour = 'contour',
  WindowDistance = 'window_distance',
  GridDistance = 'grid_distance',
  Information = 'information',
}

export enum WindowElementType {
  Frame = 'frame',
  Window = 'window',
  Mullion = 'mullion',
}

export interface MeasurementUpdateData {
  pointsToUpdate: FlatVector2Axis;
  distance: number;
  type: MeasurementElementType;
}

export type FlatVector2Axis = [FlatVector2, FlatVector2];

export enum WindowNestedMenu {
  OperationType = 'operationType',
  InnerColor = 'innerColor',
  OuterColor = 'outerColor',
  GlazingType = 'glazingType',
}

export interface WindowOffset {
  offsetX: number;
  offsetY: number;
}

export enum View {
  Inside = 'Inside',
  Outside = 'Outside',
}
