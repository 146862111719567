import { configureStore } from '@reduxjs/toolkit';
import { projectsApi } from '@/store/apis/projectsApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userApi } from '@/store/apis/userApi';
import { canvasReducer } from '@/store/slices';
import { rtkQueryErrorHandler } from '@/middlewares/error-handler';
import { windowApi } from '@/store/apis/windowApi';
import { windowsReducer } from '@/store/slices/windowsReducer';
import { blueScreenApi } from '@/store/apis/blueScreenApi';
import { undoBuildingDelete } from '@/middlewares/undoBuildingDelete';

const store = configureStore({
  reducer: {
    [projectsApi.reducerPath]: projectsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [windowApi.reducerPath]: windowApi.reducer,
    [blueScreenApi.reducerPath]: blueScreenApi.reducer,
    canvasReducer: canvasReducer,
    windowsReducer: windowsReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(projectsApi.middleware)
      .concat(userApi.middleware)
      .concat(windowApi.middleware)
      .concat(blueScreenApi.middleware)
      .concat(undoBuildingDelete.middleware)
      .concat(rtkQueryErrorHandler),
});

setupListeners(store.dispatch);

export { store };

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
